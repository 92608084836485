'use client';

import {
  getTransactionSurvey,
  setTransactionSurveyCompleted,
} from '@api/caerus';
import { getPublicUser } from '@sbt-web/auth';
import { HTTPStatusCode } from '@sbt-web/networking';
import { presencePing, type PresencePingParams } from '@sbt-web/presence-ping';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect } from 'react';
import { runAdv } from '../../helpers/adv/GPT/sdkIntegration';
import { viewSurveyTracking, viewTracking } from '../../helpers/PulseTracking';
import { AdvGoogleBox } from '../AdvGoogleBox';
import { AdvTopBanner } from '../AdvTopBanner';
import { AppWidget } from '../AppWidget';
import { CategoriesLinks } from '../FindOutMore';
import { Logo } from '../Logo';
import { PromoteWidgetWrapper } from '../PromoteWidgetWrapper';
import { SearchBox } from '../SearchBox';
import { DataLayerReadyContext } from '../SubitoScripts/DataLayerContext';

import '../../index.scss';

/**
 * A component that reserves vertical space for the touchpoints while they load,
 * to limit the layout shift above the fold.
 */
const DynamicTouchpointPlaceholder = () => <div style={{ height: '150px' }} />;

const TuttoSubitoWidget = dynamic(
  () => import('../../components/TuttoSubito'),
  {
    ssr: false,
    loading: DynamicTouchpointPlaceholder,
  }
);

const SellWidget = dynamic(() => import('../../components/SellWidget'), {
  ssr: false,
  loading: DynamicTouchpointPlaceholder,
});

const MagazineBox = dynamic(() => import('../../components/MagazineWidget'), {
  ssr: false,
  loading: DynamicTouchpointPlaceholder,
});

const SafetyTips = dynamic(() => import('../../components/SafetyTips'), {
  ssr: false,
  loading: DynamicTouchpointPlaceholder,
});

const initPresencePing = (userId: string) => {
  const presenceParams: PresencePingParams = {
    presenceApiUrl: process.env.NEXT_PUBLIC_TRUST_PRESENCE_BASE,
    profileApiUrl: process.env.NEXT_PUBLIC_TRUST_PROFILE_BASE,
    hadesPublicBase: process.env.NEXT_PUBLIC_HADES_BASE,
    userId,
    intervalTime: 3000,
    checkSetting: true,
  };

  presencePing(presenceParams);
};

export default function Home() {
  const user = getPublicUser();
  const { dataLayerReady } = useContext(DataLayerReadyContext);

  useEffect(() => {
    if (user?.id) {
      initPresencePing(user?.id);
    }
  }, [user?.id]);

  useEffect(() => {
    viewTracking();
  }, []);

  useEffect(() => {
    if (dataLayerReady) {
      runAdv();
    }
  }, [dataLayerReady]);

  useEffect(() => {
    if (!user) return;

    const notifySurveyCompleted = async () => {
      try {
        const result = await setTransactionSurveyCompleted();
        if (result.status !== HTTPStatusCode.NoContent) {
          console.error(`Cannot notify survey accepted: ${result.info}`);
        }
      } catch (e) {
        console.error(`Cannot notify survey accepted: ${e}`);
      }
    };
    const showTransactionSurvey = () => {
      async function showSurvey() {
        try {
          const result = await getTransactionSurvey();
          if (result.status === HTTPStatusCode.NoContent) {
            viewSurveyTracking();
            window.KAMPYLE_ONSITE_SDK.showForm('23977');
            notifySurveyCompleted();
          }
        } catch {
          console.warn('Cannot check for transaction surveys');
        }
      }
      showSurvey();
    };

    const loadMedalliaSDK = () => {
      if (window.Didomi.getUserConsentStatusForVendor('c:medallia-ihzryFLY')) {
        const script = document.createElement('script');
        script.src = 'https://nebula-cdn.kampyle.com/we/293639/onsite/embed.js';
        script.async = true;
        document.head.appendChild(script);
      }
    };

    window.addEventListener('neb_OnsiteLoaded', showTransactionSurvey);
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(loadMedalliaSDK);

    return () => {
      window.removeEventListener('neb_OnsiteLoaded', showTransactionSurvey);
    };
  }, [user]);

  return (
    <>
      <div className="standard-container">
        <Logo />
        <SearchBox />
      </div>

      <div className="standard-container">
        <PromoteWidgetWrapper />
      </div>

      <AdvTopBanner />

      <div className="standard-container standard-container--with-bottom-padding dynamic-atf-container">
        <TuttoSubitoWidget />
        <SellWidget />
        <MagazineBox />
        <AdvGoogleBox />
      </div>

      <SafetyTips />

      <div className="standard-container">
        <AppWidget />
        <CategoriesLinks />
      </div>
    </>
  );
}
