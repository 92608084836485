'use client';

import React, { createContext, PropsWithChildren, useState } from 'react';

const DataLayerReadyContext = createContext<{
  dataLayerReady: boolean;
  setDataLayerReady: (v: boolean) => void;
}>({
  dataLayerReady: false,
  setDataLayerReady: () => {},
});

const DataLayerReadyProvider = ({ children }: PropsWithChildren) => {
  const [dataLayerReady, setDataLayerReady] = useState<boolean>(false);

  return (
    <DataLayerReadyContext.Provider
      value={{
        dataLayerReady,
        setDataLayerReady,
      }}
    >
      {children}
    </DataLayerReadyContext.Provider>
  );
};

export { DataLayerReadyContext, DataLayerReadyProvider };
